import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
import Header from 'components/header';
import GlobalStyle from 'global.css.js';
import styled from 'styled-components';

const HeaderDiv = styled.div`
  background-color: #3ca7e1;
`;
const StyledFooter = styled.div`
  color: white;
  padding-top: 4rem;
  div,
  h1 {
    text-align: center;
    font-size: 2rem;
    font-weight: bolder;
    margin-bottom: 4rem;
  }
  div p {
    text-decoration: underline;
    display: inline;
  }
  span {
    padding: 0rem 1rem;
  }
`;

const Layout = ({ data, children }) => (
  <div style={{ 'overflow-x': 'hidden', position: 'relative' }}>
    <GlobalStyle />
    <Head />
    <HeaderDiv>
      <Header title={data.site.siteMetadata.siteTitle} />
    </HeaderDiv>
    {children}
    <StyledFooter>
      <div>
        <span role="img" aria-label="wave">
          🌊
        </span>
        <p>Based in Santa Cruz</p>
        <span role="img" aria-label="roller-coaster">
          🎢
        </span>
      </div>
      <h1>
        © {new Date().getFullYear()} calmForecast LLC. All rights reserved.
      </h1>
    </StyledFooter>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
