import React from 'react';
import { Link } from 'gatsby';
import { Container } from './nav.css';

const burgerClick = () => {
  const burger = document.querySelector('.burger');
  const nav = document.querySelector('.nav-links');
  nav.classList.toggle('nav-active');

  const navLinks = document.querySelectorAll('.nav-links li');
  navLinks.forEach((link, index) => {
    if (link.style.animation) {
      link.style.animation = '';
    } else {
      link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 +
        0.5}s`;
    }
  });
  burger.classList.toggle('toggle');
};

const Nav = () => (
  <Container>
    <ul className="nav-links">
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/about">About</Link>
      </li>
      <li>
        <Link to="/team">Team</Link>
      </li>
      <li>
        <Link to="/work">Work</Link>
      </li>
    </ul>
    <div
      className="burger"
      onClick={burgerClick}
      onKeyDown={burgerClick}
      role="button"
      tabIndex={0}
    >
      <div className="line1"></div>
      <div className="line2"></div>
      <div className="line3"></div>
    </div>
  </Container>
);

export default Nav;
