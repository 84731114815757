import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 4rem;
  padding-right: 4rem;
  height: 11rem;

  a {
    color: #3ca7e1;
    transition: color 0.2s ease;
    text-decoration: none;
    font-weight: bolder;

    &:hover {
      color: #eed550;
    }
  }
`;
