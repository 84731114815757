import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.nav`
  ul {
    display: flex;
    justify-content: space-around;
    width: 30%;
    list-style: none;
    padding: 0;
    padding-left: 20px;

    a {
      letter-spacing: 2px;
    }

    li {
      text-transform: uppercase;
      font-size: 2.4rem;
      border: medium solid #3ca7e1;
      margin: 1rem;
      padding: 1rem;
      & + li {
        margin-left: 2rem;
      }
      ${MEDIA.TABLET`
        font-size: 1.5rem;
        margin: .7rem;
        padding: .7rem;
        & + li {
          margin-left: 1.5rem;
        }
      `};
    }
  }
  .burger {
    display: none;
    cursor: pointer;
    div {
      width: 25px;
      height: 3px;
      background-color: #3ca7e1;
      margin: 5px;
      transition: all 0.3s ease;
    }
  }

  @media screen and (max-width: 1124px) {
    .nav-links {
      position: absolute;
      right: 0;
      height: 60vh;
      top: 11rem;
      background-color: black;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      transform: translateX(100%);
      transition: transform 0.5s ease-in;
      z-index: 100000;
    }
    .nav-links li {
      opacity: 0;
    }

    .burger {
      display: block;
    }
  }

  .nav-active {
    transform: translateX(0%);
  }

  @keyframes navLinkFade {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  .toggle .line2 {
    opacity: 0;
  }
  .toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
`;
