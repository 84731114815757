import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import { Container } from './header.css';
import Nav from 'components/header/nav';
import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Logo = styled.svg`
  width: 298.4px;
  height: 86px;
  ${MEDIA.TABLET`
    width: 171.2px;
    height: 49.3px;
    margin-left: -2rem;
  `}
`;

const Header = ({ title }) => (
  <AnimatedContainer>
    <Container style={{ backgroundColor: 'black' }}>
      <Link to="/">
        <Logo
          viewBox="0 0 614 177"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Group</title>
          <desc>Created with Sketch.</desc>
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Group-3" transform="translate(-32.000000, -26.000000)">
              <g id="Group" transform="translate(25.000000, 26.000000)">
                <g
                  id="Group-4"
                  transform="translate(248.895020, 39.395508)"
                  fill="#3CA7E1"
                >
                  <text
                    id="ORECAST"
                    fontFamily="GillSans-SemiBold, Gill Sans"
                    fontSize="60"
                    fontWeight="500"
                  >
                    <tspan x="40.1157227" y="100.604492">
                      ORECAST
                    </tspan>
                  </text>
                  <polygon
                    id="F"
                    fillRule="nonzero"
                    points="14.9966443 100.604492 14.9966443 54.0046354 45.2305938 54.0046354 45.2305938 41.2630462 14.9966443 41.2630462 14.9966443 13.3460813 54.5 13.3460813 54.5 0.604492188 0.5 0.604492188 0.5 100.604492"
                  ></polygon>
                </g>
                <g
                  id="CALM-2"
                  transform="translate(68.000000, 82.000000)"
                  fill="#3CA7E1"
                  fontFamily="GillSans-SemiBold, Gill Sans"
                  fontSize="60"
                  fontWeight="500"
                >
                  <text id="CALM">
                    <tspan x="0.290039062" y="57">
                      CALM
                    </tspan>
                  </text>
                </g>
                <g id="The-Sun" transform="translate(346.000000, 16.000000)">
                  <circle
                    id="Oval"
                    fill="#FFFFFF"
                    cx="137.5"
                    cy="41.5"
                    r="3.5"
                  ></circle>
                  <circle
                    id="Oval"
                    fill="#FFFFFF"
                    cx="117.5"
                    cy="25.5"
                    r="3.5"
                  ></circle>
                  <circle
                    id="Oval"
                    fill="#FFFFFF"
                    cx="90.5"
                    cy="14.5"
                    r="3.5"
                  ></circle>
                  <circle
                    id="Oval"
                    fill="#FFFFFF"
                    cx="64.5"
                    cy="14.5"
                    r="3.5"
                  ></circle>
                  <circle
                    id="Oval"
                    fill="#FFFFFF"
                    cx="39.5"
                    cy="23.5"
                    r="3.5"
                  ></circle>
                  <circle
                    id="Oval"
                    fill="#FFFFFF"
                    cx="17.5"
                    cy="41.5"
                    r="3.5"
                  ></circle>
                  <path
                    d="M141,54 C143.226388,50.6666667 145.654364,49 148.283928,49 C152.228274,49 152.493716,54 156.671156,54 C159.456115,54 162.232397,52.3333333 165,49"
                    id="Line"
                    stroke="#EED550"
                    strokeWidth="4"
                    strokeLinecap="square"
                  ></path>
                  <path
                    d="M120.10036,35.0444221 C122.301914,31.681474 124.702808,30 127.303041,30 C131.203391,30 131.465873,35.0444221 135.596717,35.0444221 C138.350612,35.0444221 141.095927,33.3629481 143.83266,30"
                    id="Line"
                    stroke="#EED550"
                    strokeWidth="4"
                    strokeLinecap="square"
                    transform="translate(131.966510, 32.522211) rotate(-26.000000) translate(-131.966510, -32.522211) "
                  ></path>
                  <path
                    d="M95.1003598,21 C97.3019142,17.6666667 99.702808,16 102.303041,16 C106.203391,16 106.465873,21 110.596717,21 C113.350612,21 116.095927,19.3333333 118.83266,16"
                    id="Line"
                    stroke="#EED550"
                    strokeWidth="4"
                    strokeLinecap="square"
                    transform="translate(106.966510, 18.500000) rotate(-41.000000) translate(-106.966510, -18.500000) "
                  ></path>
                  <path
                    d="M67.1003598,15.0444221 C69.3019142,11.681474 71.702808,10 74.3030413,10 C78.2033913,10 78.4658731,15.0444221 82.5967166,15.0444221 C85.3506123,15.0444221 88.0959267,13.3629481 90.8326598,10"
                    id="Line"
                    stroke="#EED550"
                    strokeWidth="4"
                    strokeLinecap="square"
                    transform="translate(78.966510, 12.522211) rotate(-78.000000) translate(-78.966510, -12.522211) "
                  ></path>
                  <path
                    d="M41.1003598,21.0444221 C43.3019142,17.681474 45.702808,16 48.3030413,16 C52.2033913,16 52.4658731,21.0444221 56.5967166,21.0444221 C59.3506123,21.0444221 62.0959267,19.3629481 64.8326598,16"
                    id="Line"
                    stroke="#EED550"
                    strokeWidth="4"
                    strokeLinecap="square"
                    transform="translate(52.966510, 18.522211) rotate(-98.000000) translate(-52.966510, -18.522211) "
                  ></path>
                  <path
                    d="M17.1003598,34.0444221 C19.3019142,30.681474 21.702808,29 24.3030413,29 C28.2033913,29 28.4658731,34.0444221 32.5967166,34.0444221 C35.3506123,34.0444221 38.0959267,32.3629481 40.8326598,29"
                    id="Line"
                    stroke="#EED550"
                    strokeWidth="4"
                    strokeLinecap="square"
                    transform="translate(28.966510, 31.522211) rotate(-117.000000) translate(-28.966510, -31.522211) "
                  ></path>
                  <path
                    d="M-1,51.7695097 C0.855323227,48.4361764 2.87863647,46.7695097 5.06993973,46.7695097 C8.35689462,46.7695097 8.57809678,51.7695097 12.0592963,51.7695097 C14.3800959,51.7695097 16.6936638,50.102843 19,46.7695097"
                    id="Line"
                    stroke="#EED550"
                    strokeWidth="4"
                    strokeLinecap="square"
                    transform="translate(9.000000, 49.269510) rotate(-133.000000) translate(-9.000000, -49.269510) "
                  ></path>
                  <path
                    d="M8,62 L148,62 C124.900308,34 101.566974,20 78,20 C54.4330255,20 31.0996922,34 8,62 Z"
                    id="Path-2"
                    fill="#EED550"
                  ></path>
                  <text
                    id="LLC"
                    fontFamily="GillSans, Gill Sans"
                    fontSize="33"
                    fontWeight="normal"
                    fill="#3CA7E1"
                  >
                    <tspan x="50.1401367" y="55">
                      LLC
                    </tspan>
                  </text>
                </g>
                <g
                  id="cloud-2"
                  transform="translate(69.000000, 0.000000)"
                  fill="#FFFFFF"
                >
                  <path
                    d="M16.7600521,79 L149.500713,79 C157.833571,72.3744779 162,65.3258571 162,57.8541374 C162,50.3824178 157.833571,43.6226387 149.500713,37.5748003 C150.704993,23.6104083 145.433377,14.1974121 133.685865,9.33581167 C116.41491,2.79414037 102.403343,5.49751022 91.6511636,17.4459212 C85.1803769,3.69278293 72.8341834,-1.92884956 54.6125832,0.581023757 C36.390983,3.09089707 26.5358996,12.3815215 25.047333,28.4528972 C8.349111,32.244685 0,40.1640889 0,52.2111087 C0,64.2581285 5.58668403,73.1877589 16.7600521,79 Z"
                    id="cloud"
                  ></path>
                </g>
                <g
                  id="boat-2"
                  transform="translate(0.000000, 130.000000)"
                  fill="#FFFFFF"
                >
                  <path
                    d="M583.053945,47 L48.2371753,47 C45.4664186,47 42.8198562,45.8504094 40.9287014,43.8254091 L8.24871581,8.83251441 C7.49480194,8.02524268 7.53805704,6.75965169 8.34532878,6.00573782 C8.971122,5.42130736 9.89952916,5.30008161 10.6544053,5.70423268 L41.6819786,22.3159996 C43.1338107,23.0932919 44.7551373,23.5 46.4019523,23.5 L584.948726,23.5 C586.716861,23.5 588.453384,23.0311949 589.981294,22.1413689 L617.747411,5.97091249 C618.70191,5.41503033 619.926316,5.73817334 620.482198,6.69267258 C620.910899,7.42879025 620.826287,8.35566759 620.271386,9.00199479 L590.641253,43.5140427 C588.74148,45.7268267 585.970373,47 583.053945,47 Z"
                    id="boat"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </Logo>
      </Link>

      <Nav />
    </Container>
  </AnimatedContainer>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
